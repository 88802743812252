<template>
  <div class="auth__block">
    <h1 class="title-h2 auth__title">
      <i18n path="main.sign_in_to_account">
        <br />
      </i18n>
    </h1>
    <div class="auth__fields-wrapper" @keyup.prevent.enter="authHandler">
      <text-field
        id="email"
        v-model="email"
        type="email"
        placeholder="E-mail"
        class="auth__field auth__email"
        :is-error="errorEmailEmptyField || errorEmailInvalidField"
        :error-text="emailErrorText"
      />
      <text-field
        id="password"
        v-model="password"
        type="password"
        :placeholder="$t('main.password')"
        is-password
        class="auth__field"
        :is-error="errorPasswordField"
      />
    </div>
    <div @click="$router.push('/reset-password')">
      <p class="auth__forgot_pass">Забули пароль ?</p>
    </div>
    <div class="auth__btn-wrapper">
      <Button
        class="auth__btn"
        :is-icon="true"
        isRightIcon
        iconName="arrow"
        @click.native.prevent="authHandler"
        >{{ $t('main.enter') }}
      </Button>
    </div>
  </div>
</template>

<script>
import authValidation from '@/mixins/authValidationMixin'
import TextField from '@/elements/TextField.vue'

export default {
  mixins: [TextField, authValidation],
  data() {
    return {
      email: '',
      password: '',
    }
  },

  methods: {
    authHandler() {
      if (this.$v.$invalid) {
        this.showAuthValidationErrors()
        return
      }
      this.$store.dispatch('authorisation', {
        email: this.email,
        password: this.password,
        redirect: this.$route.params.redirect,
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.auth__email
  margin-bottom: 4rem

.auth__forgot_pass
  text-decoration: underline
  cursor: pointer
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  -o-user-select: none
  user-select: none
</style>

